<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="career" class="fadeIn">
      <h1 class="title-md">
        Join Us to <span class="text-primary">Shape the Future</span> of
        Logistics
      </h1>

      <div class="d-flex justify-content-center">
        <div class="mt-5 job-card-container">
          <div v-if="!jobs || !jobs.length" class="no-job-container p-5">
            <img
              src="@/assets/images/icons/main/resume-empty.svg"
              width="200"
              style="opacity: 80%"
            />
            <h1 class="text-dark">No jobs available</h1>
          </div>
          <JobCard v-else :jobs="jobs" />
        </div>
      </div>
    </div>

    <question-content :is-question="false" class="fadeIn">
      <div class="drop-resume mt-2 mt-md-0">
        <h1 class="title-md text-white text-center">
          Didn’t find your desired position? Drop us your
          <span>resume</span>!
        </h1>
        <div class="d-flex justify-content-center">
          <b-button
            class="btn mt-2 resume-btn"
            :href="`mailto:${emailAddress}?subject=${dropSubject}`"
          >
            Drop Resume
          </b-button>
        </div>
      </div>
    </question-content>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import JobCard from "@/components/JobCard.vue";
import QuestionContent from "@/components/QuestionContent.vue";

export default {
  components: { JobCard, QuestionContent },
  mixins: [intersectionObserverMixin],
  data() {
    return {
      emailAddress: "weiying@glogc.com",
      dropSubject: "Resume Drop",
      jobs: [],
    };
  },
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.2);
  },
};
</script>
<style lang="scss">
.job-card-container {
  width: 90%;
  max-width: 1200px !important;
}

.drop-resume {
  margin-top: -50px;
  p {
    color: white;
    font-size: 0.9rem;
  }
  h1 {
    line-height: 120%;
  }
  h1 span {
    font-style: italic;
    font-family: "Playfair Display", serif;
    letter-spacing: -1.28px;
    font-weight: 500;
  }
  .resume-btn {
    background: none !important;
    color: white;
    border: 1px solid white !important;
  }
  .resume-btn:hover {
    background-color: white !important;
    color: red !important;
    font-weight: 500;
  }
}

.no-job-container {
  height: 65vh;
  text-align: center;
}
</style>
