<template>
  <div id="question" class="d-md-flex">
    <div class="d-flex justify-content-center align-items-end">
      <img
        src="@/assets/images/main/question-women.png"
        class="d-none d-md-block"
      />
      <img src="@/assets/images/main/q-women.png" class="d-block d-md-none" />
    </div>
    <div class="right-content sm-center">
      <div v-if="isQuestion">
        <h1 class="title-md text-white">
          Have any questions? <br />
          Click the button below!
        </h1>
        <router-link class="btn btn-primary my-2" :to="{ name: 'Contact' }">
          Contact Us
        </router-link>
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isQuestion: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
