<template>
  <div id="job-card">
    <div v-for="job in jobs" :key="job.id" class="job-card my-3">
      <h2>{{ job.title }}</h2>
      <p>{{ job.description }}</p>

      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <h5 class="mr-2">
            <feather-icon icon="MapPinIcon" width="17" class="text-primary" />
            {{ job.location }}
          </h5>

          <h5>
            <feather-icon icon="ClockIcon" width="17" class="text-primary" />
            {{ job.contract }}
          </h5>
        </div>
        <div class="d-flex">
          <router-link
            class="mr-1 border-btn px-1"
            :to="{ name: 'CareerJob', params: { job: job.title, id: job.id } }"
          >
            Learn More
          </router-link>
          <b-button
            variant="primary"
            class="btn-sm"
            :href="`mailto:${emailAddress}?subject=${applySubject}`"
          >
            Apply
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    jobs: {
      type: Object,
    },
  },
  data() {
    return {
      emailAddress: "weiying@glogc.com",
      applySubject: `Application for ${this.job} position`,
    };
  },
};
</script>
